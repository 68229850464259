import React from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import { Button, Table } from "flowbite-react";
import NoData from "components/nodata";

function ColumnsTableKOL({ tableName, columns, data, onView }: any) {

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <header className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-indigo-700 dark:text-white">
          {tableName}
        </div>
        {/* <CardMenu /> */}
      </header>

      <div className="mt-8 overflow-x-scroll w-full h-full">
        {
          data?.length > 0 ? <Table hoverable >
            <Table.Head>
              {
                columns.map((item: { Header: string, key: string }) => (
                  <Table.HeadCell key={item.key} className="uppercase text-sm font-bold">
                    {item.Header}
                  </Table.HeadCell>
                ))
              }
            </Table.Head>
            <Table.Body className="divide-y">
              {
                data?.length > 0 ? (data.map((row: any, rowIndex: number) => (
                  <Table.Row key={rowIndex} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    {columns.map((column: any, colIndex: number) => (
                      <Table.Cell key={colIndex} className="whitespace-nowrap text-sm font-bold text-gray-900 dark:text-white first:text-indigo-700 w-full">
                        {column.key === "action" ? (
                          <Button
                            onClick={() => onView(row.partnerID)}
                            size="md"
                            color="primary"
                            className="bg-indigo-700 text-white active:translate-y-0.5 hover:bg-indigo-800 hover:text-white"
                          >
                            View
                          </Button>
                        ) : column.key === "refLink" ? (
                          <div className="flex flex-col items-start">
                            <a className="text-indigo-700 dark:text-white hover:underline" href={`https://t.me/catgoldminerbot?start=${row.partnerID}`} target="_blank" rel="noreferrer">
                              {`https://t.me/catgoldminerbot?start=${row.partnerID}`}
                            </a>
                            <a className="text-indigo-700 dark:text-white hover:underline" href={`https://t.me/catgoldminerbot/ido?startapp=${row.partnerID}`} target="_blank" rel="noreferrer">
                              {`https://t.me/catgoldminerbot/ido?startapp=${row.partnerID}`}
                            </a>
                          </div>
                        ) : row[column.key]}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))
                ) : null
              }
            </Table.Body>
          </Table> : <NoData />
        }

      </div>
    </Card>
  );
}

export default ColumnsTableKOL;
