import Widget from "components/widget/Widget";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { FaUser, FaLink, FaKey, FaDownload, FaPlus } from "react-icons/fa";
import { FaGamepad } from "react-icons/fa6";
import { MdFiberNew, MdVerifiedUser, MdDateRange } from "react-icons/md";
import { DiGoogleAnalytics } from "react-icons/di";
import { TiArrowBack } from "react-icons/ti";
import Datepicker from "react-tailwindcss-datepicker";
import { partnerDashboardAPI } from "server";
import { convertDateTime, convertUTCAfter, convertUTCBefore } from "utils/utils";
import ColumnsTable from "./components/ColumnsTable";
import TrafficsCATGMChart from "./components/TrafficsCATGMChart";
import TrafficsPartnerChart from "./components/TrafficsPartnerChart";
import { catgmTrafficsColumns } from "./variables/catgmTrafficsColumns";
import { partnerTrafficsColumns } from "./variables/partnerTrafficsColumns";
import { HiMiniCurrencyDollar, HiUserCircle } from "react-icons/hi2";
import Card from "components/card";
import { Button, CustomFlowbiteTheme, Flowbite, Label, Modal, Tabs, TextInput } from "flowbite-react";
import { MdTaskAlt } from "react-icons/md";
import { partnerLogType } from "variables/refType";
import NoData from "components/nodata";
import { toast } from "react-hot-toast";
import ColumnsTableKOL from "./components/ColumnsTableKOL";


const CrossQuest = () => {
  const [partner, setPartner] = useState(JSON.parse(sessionStorage.getItem("admin")))
  const [profile, setProfile] = useState<any>()
  const [report, setReport] = useState<any>()
  const [groupData, setGroupData] = useState<any>()
  const [paymentTotal, setPaymentTotal] = useState<any>()
  const [partnerTraffics, setPartnerTraffics] = useState<any>()
  const [catgmTraffics, setCatgmTraffics] = useState<any>()
  const [trafficsData, setTrafficsData] = useState<any>()

  const kolListColumns = [
    { Header: "KOL ID", key: "partnerID" },
    { Header: "KOL Name", key: "partnerName" },
    { Header: "Ref Link", key: "refLink" },
    { Header: "Action", key: "action" },
  ]


  const [value, setValue] = useState<any>({
    startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
    endDate: new Date()
  });

  const dateQuery = useDebounce(value, 1000)
  const handleValueChange = (newValue: any) => {
    if (newValue?.startDate && newValue?.endDate) {
      setValue({
        startDate: new Date(newValue?.startDate),
        endDate: new Date(newValue?.endDate)
      });
    }
  };

  const getUserPaymentTotal = async () => {
    if (partner && partner?.partnerID) {
      const res: any = await partnerDashboardAPI.getUserPaymentTotal(partner.partnerID)
      if (res?.code === 0) {
        setPaymentTotal(res.data)
      }
    }
  }

  const [paymentTotalByKOL, setPaymentTotalByKOL] = useState<any>()
  const getUserPaymentTotalByKOL = async (kolID: string) => {
    if (kolID) {
      const res: any = await partnerDashboardAPI.getUserPaymentTotalByKOL(kolID)
      if (res?.code === 0) {
        setPaymentTotalByKOL(res.data)
      }
    }
  }

  const getProfile = async () => {
    if (partner && partner?.partnerID) {
      const res: any = await partnerDashboardAPI.getProfile(partner.partnerID)
      if (res?.code === 0) {
        setProfile(res.data)
      }
    }
  }

  const getTrackingReport = async () => {
    if (partner && partner?.partnerID) {
      const res: any = await partnerDashboardAPI.getTrackingReport(convertUTCBefore(value.startDate), convertUTCAfter(value.endDate))
      if (res?.code === 0) {
        setReport(res.data)
      }
    }
  }

  const [reportByKOL, setReportByKOL] = useState<any>()
  const [selectedKOL, setSelectedKOL] = useState<any>(null);


  const getTrackingReportByKOL = async (kolID: string) => {
    if (kolID) {
      setSelectedKOL({ id: kolID });
      const res: any = await partnerDashboardAPI.getTrackingReportByKOL(kolID, convertUTCBefore(value.startDate), convertUTCAfter(value.endDate))
      if (res?.code === 0) {
        setReportByKOL(res.data)
      }
    }
  }

  useEffect(() => {
    if (selectedKOL) {
      getTrackingReportByKOL(selectedKOL.id)
      getUserPaymentTotalByKOL(selectedKOL.id)
    }
  }, [dateQuery])



  useEffect(() => {

    const group = groupAndSortData(report)
    if (group?.length > 0) {
      const listData: any = []
      group.forEach((item: any) => {
        const partnerData = item?.dates?.map((item: any) => ({
          date: item?.date,
          questUsers: item?.data[partnerLogType.DO_QUEST]?.count || 0,
          verifiedUsers: item?.data[partnerLogType.VERIFIED_QUEST]?.count || 0,
        }))

        const catgmData = item?.dates?.map((item: any) => {
          // console.log("🚀 ~ catgmData ~ item:", Number(item?.data[partnerLogType.NEW_USER]?.count))

          return ({
            date: item?.date,
            totalUsers: ((item?.data[partnerLogType.NEW_USER]?.count || 0) + (item?.data[partnerLogType.OLD_USER]?.count || 0)) || 0,
            newUsers: item?.data[partnerLogType.NEW_USER]?.count || 0,
            gameUsers: item?.data[partnerLogType.GAME_USER]?.count || 0
          })
        })
        listData.push({ partnerData, catgmData })
      })
      console.log("🚀 ~ group.forEach ~ listData:", listData)


      setTrafficsData(listData)
    } else {
      setTrafficsData([])

    }

    setGroupData(group)

  }, [report])

  const [groupByKOL, setGroupByKOL] = useState<any>()
  const [trafficsByKOL, setTrafficsByKOL] = useState<any>()
  useEffect(() => {

    const group = groupAndSortData(reportByKOL)
    if (group?.length > 0) {
      const listData: any = []
      group.forEach((item: any) => {
        const partnerData = item?.dates?.map((item: any) => ({
          date: item?.date,
          questUsers: item?.data[partnerLogType.DO_QUEST]?.count || 0,
          verifiedUsers: item?.data[partnerLogType.VERIFIED_QUEST]?.count || 0,
        }))

        const catgmData = item?.dates?.map((item: any) => {
          // console.log("🚀 ~ catgmData ~ item:", Number(item?.data[partnerLogType.NEW_USER]?.count))

          return ({
            date: item?.date,
            totalUsers: ((item?.data[partnerLogType.NEW_USER]?.count || 0) + (item?.data[partnerLogType.OLD_USER]?.count || 0)) || 0,
            newUsers: item?.data[partnerLogType.NEW_USER]?.count || 0,
            gameUsers: item?.data[partnerLogType.GAME_USER]?.count || 0
          })
        })
        listData.push({ partnerData, catgmData })
      })
      console.log("🚀 ~ group.forEach ~ listData kol:", listData)

      setTrafficsByKOL(listData)
    } else {
      setTrafficsByKOL([])

    }

    setGroupByKOL(group)

  }, [reportByKOL])

  useEffect(() => {
    if (selectedKOL) {
      getUserPaymentTotalByKOL(selectedKOL.id)
    }
  }, [selectedKOL])


  useEffect(() => {
    getTrackingReport()
  }, [dateQuery])

  useEffect(() => {
    if (partner && partner.partnerID) {
      getProfile()
      getUserPaymentTotal()
    }
  }, [partner])

  useEffect(() => {
    if (profile?.isVIP) {
      getKOLList()
    }
  }, [profile])


  interface DataItem {
    _id: string;
    partnerID: string;
    refType: number;
    questValue: string;
    date: string;
    __v: number;
    createAt: string;
    trackingCount: number;
    updateAt: string;
  }

  interface RefTypeTotal {
    count: number;
    items: DataItem[];
  }

  interface DateData {
    date: string;
    data: Record<number, RefTypeTotal>;
  }

  interface QuestValueData {
    questValue: string;
    refTypeTotals: Record<number, number>;
    dates: DateData[];
  }

  function parseDateString(dateString: string): Date {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  function groupAndSortData(data: DataItem[]): QuestValueData[] {
    if (data && data?.length > 0) {


      // First, group by questValue
      const groupedByQuestValue = data?.reduce((acc, item) => {
        if (!acc[item.questValue]) {
          acc[item.questValue] = [];
        }
        acc[item.questValue].push(item);
        return acc;
      }, {} as Record<string, DataItem[]>);

      // For each questValue, group by date and then by refType
      const result = Object.entries(groupedByQuestValue).map(([questValue, items]) => {
        // Group by date
        const groupedByDate = items.reduce((acc, item) => {
          if (!acc[item.date]) {
            acc[item.date] = [];
          }
          acc[item.date].push(item);
          return acc;
        }, {} as Record<string, DataItem[]>);

        // Sort dates
        const sortedDates = Object.keys(groupedByDate).sort((a, b) => {
          const dateA = parseDateString(a);
          const dateB = parseDateString(b);
          return dateA.getTime() - dateB.getTime();
        });

        const refTypeTotals: Record<number, number> = {};

        // For each date, group by refType and sum trackingCount
        const datesData = sortedDates.map(date => {
          const groupedByRefType = groupedByDate[date].reduce((acc, item) => {
            if (!acc[item.refType]) {
              acc[item.refType] = {
                count: 0,
                items: []
              };
            }
            acc[item.refType].count += item.trackingCount;
            acc[item.refType].items.push(item);

            // Update total counts
            refTypeTotals[item.refType] = (refTypeTotals[item.refType] || 0) + item.trackingCount;

            return acc;
          }, {} as Record<number, RefTypeTotal>);

          return {
            date,
            data: groupedByRefType
          };
        });

        return {
          questValue,
          refTypeTotals,
          dates: datesData
        };
      });

      return result;
    }
  }

  const [openModal, setOpenModal] = useState(false)
  const [kolID, setKolID] = useState('')
  const [kolName, setKolName] = useState('')
  const [kolList, setKolList] = useState<any>([])

  function onCloseModal() {
    setOpenModal(false);
    setKolID('');
    setKolName('');
  }

  const handleAddKol = async () => {
    if (kolID && kolName) {
      const res: any = await partnerDashboardAPI.addKol(kolID, kolName)
      if (res?.code === 0) {
        toast.success("Add KOL successfully")
        setOpenModal(false);
        setKolID('');
        setKolName('');
        getKOLList()
      } else {
        toast.error("Add KOL failed")
      }
    } else {
      toast.error("Please enter KOL ID and KOL Name")
    }
  }

  const getKOLList = async () => {
    const res: any = await partnerDashboardAPI.getKOLList()
    if (res?.code === 0) {
      setKolList(res.data)
    }
  }



  return (
    <div>
      {/* Card widget */}
      <div className="flex flex-col md:flex-row justify-between items-center gap-5">
        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-4">
          <Widget
            count={true}
            icon={<FaUser className="h-7 w-7" />}
            title={"Number of Paid Users"}
            subtitle={paymentTotal?.numberPaidUsers ? paymentTotal?.numberPaidUsers : 0}
          />
          <Widget
            count={true}
            money={true}
            icon={<HiMiniCurrencyDollar className="h-7 w-7" />}
            title={"Total Spend (USD)"}
            subtitle={paymentTotal?.totalSpend ? paymentTotal?.totalSpend : 0}
          />
        </div>
        {/* {profile?.isVIP && <Button className="flex items-center justify-center gap-2 bg-indigo-700 hover:bg-indigo-800 text-white active:translate-y-0.5" onClick={() => setOpenModal(true)}>
          <FaPlus className="h-5 w-5 mr-2" />
          <span>Add KOL</span>
        </Button>} */}
      </div>
      <Modal show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Add KOL to monitor</h3>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="kolID" value="KOL ID" />
              </div>
              <TextInput
                id="kolID"
                placeholder="Please enter user ID of your KOL"
                value={kolID}
                onChange={(event) => setKolID(event.target.value)}
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="kolName" value="KOL Name" />
              </div>
              <TextInput
                id="kolName"
                placeholder="Please enter name of your KOL"
                value={kolName}
                onChange={(event) => setKolName(event.target.value)}
                required
              />
            </div>
            <div className="w-full flex justify-end">
              <Button className="bg-indigo-700 hover:bg-indigo-800 text-white active:translate-y-0.5" onClick={handleAddKol}>Confirm</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Charts */}
      <div className="my-5 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4">
        <div className="col-start-1 md:col-start-2 xl:col-start-4">
          <Datepicker
            primaryColor={"indigo"}
            inputClassName=" w-full text-black dark:!text-white dark:!bg-navy-800 rounded-[10px] border-[1px] border-gray-200 shadow-md shadow-[#f3f3f35] py-4"
            showShortcuts={true}
            value={value}
            onChange={handleValueChange}
          />
        </div>
      </div>

      {
        groupData?.length > 0 ? groupData?.map((item: any, index: any) => (
          <div key={index}>
            {
              item?.questValue === "crossQuest" && (
                <Card extra="rounded-[20px] p-5">

                  <>
                    <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-4">
                      <Widget
                        icon={<FaLink className="h-5 w-5" />}
                        title={"Quest Link:"}
                        subtitle={` https://t.me/catgoldminerbot?start=${profile?.partnerID}`}
                      />
                      <Widget
                        count={true}
                        icon={<DiGoogleAnalytics className="h-7 w-7" />}
                        title={"Total Traffic to CATGM"}
                        subtitle={((item?.refTypeTotals[partnerLogType.OLD_USER] || 0) + (item?.refTypeTotals[partnerLogType.NEW_USER] || 0)) || 0}
                      />
                      <Widget
                        count={true}
                        icon={<MdFiberNew className="h-7 w-7" />}
                        title={"Total New Users to CATGM"}
                        subtitle={item?.refTypeTotals[partnerLogType.NEW_USER] ? item?.refTypeTotals[partnerLogType.NEW_USER] : 0}
                      />
                      <Widget
                        count={true}
                        icon={<FaGamepad className="h-7 w-7" />}
                        title={"Total Game Users to CATGM"}
                        subtitle={item?.refTypeTotals[partnerLogType.GAME_USER] ? item?.refTypeTotals[partnerLogType.GAME_USER] : 0}
                      />
                    </div>
                    <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
                      <div className="col-span-1 w-full h-full">
                        <ColumnsTable tableName="Traffic from Partner to CATGM" columns={catgmTrafficsColumns} data={trafficsData[index]?.catgmData} />
                      </div>
                      <div className="col-span-1 w-full h-full">
                        {trafficsData && trafficsData[index]?.catgmData && <TrafficsCATGMChart data={trafficsData[index]?.catgmData} />}
                      </div>
                    </div>
                  </>
                </Card >
              )
            }
          </div>
        )) : <Card extra="p-5">
          <div className="h-screen">
            <NoData />
          </div>
        </Card>
      }
      {
        profile?.isVIP && (
          <Card extra="mt-5 rounded-[20px] p-5">
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-bold text-indigo-700 dark:text-white">KOL MONITORING</h3>
              <Button className="bg-indigo-700 hover:bg-indigo-800 text-white active:translate-y-0.5" onClick={() => setOpenModal(true)}>
                <FaPlus className="h-5 w-5 mr-2" />
                <span>Add KOL</span>
              </Button>
            </div>
            {kolList?.length > 0 && (
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                <div className="mt-5">
                  <ColumnsTableKOL tableName="KOL List" columns={kolListColumns} data={kolList} onView={getTrackingReportByKOL} />
                </div>
                <div className="mt-5">
                  <Card extra="rounded-[20px] p-5 h-full">
                    <h4 className="text-lg font-semibold mb-4 text-indigo-700 dark:text-white">
                      {selectedKOL ? `Viewing KOL ID:${selectedKOL.id}` : 'Select a KOL to view their data'}
                    </h4>
                    {paymentTotalByKOL && (
                      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
                        <Widget
                          count={true}
                          icon={<FaUser className="h-7 w-7" />}
                          title={"Number of Paid Users (All Time)"}
                          subtitle={paymentTotalByKOL?.numberPaidUsers ? paymentTotalByKOL?.numberPaidUsers : 0}
                        />
                        <Widget
                          count={true}
                          money={true}
                          icon={<HiMiniCurrencyDollar className="h-7 w-7" />}
                          title={"Total Spend (USD) (All Time)"}
                          subtitle={paymentTotalByKOL?.totalSpend ? paymentTotalByKOL?.totalSpend : 0}
                        />
                      </div>
                    )}
                    {
                      groupByKOL?.length > 0 ? groupByKOL?.map((item: any, index: any) => (
                        <div key={index}>
                          {
                            item?.questValue === "crossQuest" && (
                              <>
                                <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3">
                                  {/* <Widget
                                    icon={<FaLink className="h-5 w-5" />}
                                    title={"Quest Link:"}
                                    subtitle={` https://t.me/catgoldminerbot?start=${item?.partnerID}`}
                                  /> */}
                                  <Widget
                                    count={true}
                                    icon={<DiGoogleAnalytics className="h-7 w-7" />}
                                    title={"Total Traffic to CATGM"}
                                    subtitle={((item?.refTypeTotals[partnerLogType.OLD_USER] || 0) + (item?.refTypeTotals[partnerLogType.NEW_USER] || 0)) || 0}
                                  />
                                  <Widget
                                    count={true}
                                    icon={<MdFiberNew className="h-7 w-7" />}
                                    title={"Total New Users to CATGM"}
                                    subtitle={item?.refTypeTotals[partnerLogType.NEW_USER] ? item?.refTypeTotals[partnerLogType.NEW_USER] : 0}
                                  />
                                  <Widget
                                    count={true}
                                    icon={<FaGamepad className="h-7 w-7" />}
                                    title={"Total Game Users to CATGM"}
                                    subtitle={item?.refTypeTotals[partnerLogType.GAME_USER] ? item?.refTypeTotals[partnerLogType.GAME_USER] : 0}
                                  />
                                </div>
                                <div className="mt-5 grid grid-cols-1 gap-5">
                                  <div className="col-span-1 w-full h-full">
                                    <ColumnsTable tableName="Traffic from KOL to CATGM" columns={catgmTrafficsColumns} data={trafficsByKOL[index]?.catgmData} />
                                  </div>
                                </div>
                              </>
                            )
                          }
                        </div>
                      )) :
                        <div className="h-full">
                          {
                            selectedKOL ? <div>
                              <NoData />
                              <p className="text-center text-indigo-700 dark:text-white mt-5">This KOL has not referred any users at this range of time</p>
                            </div> : <div className="flex justify-center items-center h-full">
                              <h3 className="text-lg font-semibold text-indigo-700 dark:text-white">Please select a KOL to view their data</h3>
                            </div>
                          }
                        </div>

                    }
                  </Card>
                </div>
              </div>
            )}
          </Card>
        )
      }
    </div >
  );
};

export default CrossQuest;
